import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import Axios from 'axios';


import { logoutUser } from '../../actions/authAction';


import Web3 from "xdc3";
import { APPROVE_STAKE, MINIMUM_STAKE, PLUGINFARM_CONTRACT, PLUGIN_CONTRACT, PLUGIN_DEFI_CONTRACT } from '../../actions/constant';
import PluginContract from '../../contracts/abi.json';
import { convertTokens, getTxnStatus } from '../../utils/contract';
import { ConnectButton } from '@rainbow-me/rainbowkit';

class SidebarUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tillStake: 0,
      //contract
      tokenBalance: 0,
      accountAddress: "",
      accountBalance: 0,
      pluginContract: null,
      pluginFarmDefiContract: null,
    }
  }
  async onLogoutClick(e) {
    e.preventDefault();
    const { web3modal } = this.props.web3
    await web3modal.clearCachedProvider();
    this.props.logoutUser();
  }
  async componentDidMount() {

    await this.loadWeb3();
    await this.loadBlockchainData();

  }

  loadBlockchainData = async () => {
    const web3 = window.web3;
    const accounts = await web3.eth.getAccounts();
    const { user } = this.props.auth
    this.setState({ accountAddress: accounts[0] });
    let accountBalance = await web3.eth.getBalance(accounts[0]);
    accountBalance = web3.utils.fromWei(accountBalance, "Ether");
    this.setState({ accountBalance });
    if (1) {
      const pluginContract = new web3.eth.Contract(
        PluginContract,
        PLUGIN_CONTRACT
      );

      this.setState({ pluginContract }, () => {
        this.getPliBalance()
      });
    }

  }

  loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {

    }
  };
  connectToMetamask = async () => {
    await window.ethereum.enable();
    this.setState({ metamaskConnected: true });
    window.location.reload();
  };
  async getPliBalance() {
    const rate = await this.state.pluginContract.methods.balanceOf(this.state.accountAddress).call().then(res => {
      const tokens = window.web3.utils.fromWei(res.toString(), 'ether');
      console.log("tok values", tokens);
      this.setState({
        tokenBalance: parseFloat(tokens).toFixed(4)
      })

    });
  }


  render() {
    const navlinks = [
      { name: "Dashboard", link: "/dashboard", icon: <em className="icon ni ni-dashboard" /> },
      { name: "Xchange", link: "/xchange", icon: <em className="icon ni ni-list-index" /> },
    ]
    const pathname = window.location.pathname;

    return (
      <div className="nk-sidebar nk-sidebar-fixed " data-content="sidebarMenu">
        <div className="nk-sidebar-element nk-sidebar-head">
          <div className="nk-sidebar-brand">
            <Link to="/dashboard" className="logo-link nk-sidebar-logo">
              <img style={{ width: "70%" }} className="logo-light logo-img" src="./images/logo-new-2.png" srcSet="./images/logo-new-2.png 2x" alt="logo" />
              <img style={{ width: "70%" }} className="logo-dark logo-img" src="./images/logo-new-2.png" srcSet="./images/logo-new-2.png 2x" alt="logo-dark" />
            </Link>
          </div>
          <div className="nk-menu-trigger me-n2">
            <Link to="/dashboard" className="nk-nav-toggle nk-quick-nav-icon d-xl-none" data-target="sidebarMenu"><em className="icon ni ni-arrow-left" /></Link>
          </div>
        </div>{/* .nk-sidebar-element */}
        <div className="nk-sidebar-element">
          <div className="nk-sidebar-body" data-simplebar>
            <div className="nk-sidebar-content">
              <div className="nk-sidebar-widget d-none d-xl-block">
                <div className="user-account-info between-center">
                  <div className="user-account-main">
                    <h6 className="overline-title-alt">Available  Balance</h6>
                    <div className="user-balance">{parseFloat(this.state.tokenBalance).toFixed(4)} <small className="currency currency-btc">PLI</small></div>
                    <div className="user-balance-alt">{parseFloat(this.state.accountBalance).toFixed(4)} <span className="currency currency-btc">XDC</span></div>
                  </div>
                </div>
                <div className="user-account-actions">
                  <ul className="g-3">
                    <li>
                      <a target="_blank" href="https://faucet.apothem.network" className="btn btn-lg btn-primary">
                        <span>Get XDC</span>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://faucet.goplugin.co" className="btn btn-lg btn-warning">
                        <span>Get PLI</span>
                      </a>
                    </li>
                  </ul>
                </div>

              </div>{/* .nk-sidebar-widget */}

              <div className="nk-sidebar-menu">
                {/* Menu */}
                <ul className="nk-menu">
                  <li className="nk-menu-heading">
                    <h6 className="overline-title">Menu</h6>
                  </li>
                  {navlinks.map(result => {
                    return <li className={`nk-menu-item ${pathname === result.link ? "active current-page" : null}`}>
                      <Link to={result.link} className="nk-menu-link">
                        <span className="nk-menu-icon">{result.icon}</span>
                        <span className="nk-menu-text">{result.name}</span>
                      </Link>
                    </li>
                  })}
                  {/* <li className={`nk-menu-item`}>
                    <a href='https://faucet.apothem.network' className="nk-menu-link">
                      <span className="nk-menu-icon">
                        <img src="https://www.xinfin.io/assets/images/brand-assets/xdc-icon.png" />
                      </span>
                      <span className="nk-menu-text">Get XDC</span>
                    </a>
                  </li>
                  <li className={`nk-menu-item`}>
                    <a href='https://faucet.goplugin.co' className="nk-menu-link">
                      <span className="nk-menu-icon"><em className="icon ni ni-signout" /></span>
                      <span className="nk-menu-text">Get PLI</span>
                    </a>
                  </li> */}
                  {/* <li className={`nk-menu-item`}>
                    <Link to={"/logout"} onClick={this.onLogoutClick.bind(this)} className="nk-menu-link">
                      <span className="nk-menu-icon"><em className="icon ni ni-signout" /></span>
                      <span className="nk-menu-text">Logout</span>
                    </Link>
                  </li> */}
                </ul>{/* .nk-menu */}
              </div>{/* .nk-sidebar-menu */}
            </div>{/* .nk-sidebar-content */}
          </div>{/* .nk-sidebar-body */}
        </div>{/* .nk-sidebar-element */}
      </div>
    )
  }
}
SidebarUser.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  web3: state.web3,
  errors: state.errors
})

export default connect(mapStateToProps, { logoutUser })(SidebarUser);
