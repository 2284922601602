import React, { Component, useState, useContext, useEffect, } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom'
import SidebarUser from '../../layouts/SidebarUser'
import Header from '../../layouts/Header'
import truncateEthAddress from 'truncate-eth-address'
import Footer from '../../layouts/Footer';
import { logoutUser } from "../../../actions/authAction"
import { Loading } from 'notiflix/build/notiflix-loading-aio';
import { Confirm } from 'notiflix/build/notiflix-confirm-aio';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { Report } from 'notiflix/build/notiflix-report-aio';

import { useSelector, useDispatch } from 'react-redux'
import { EXPLORER_URL } from '../../../actions/constant';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount, useDisconnect } from 'wagmi';


const { executeTransaction, EthereumContext, log, queryData, convertPriceToEth, convertPricefromEth, queryEvents } = require('react-solidity-web3');

Confirm.init({
  titleColor: '#244b81',
  okButtonBackground: "#244b81"
});

Report.init({
  titleColor: '#244b81',
  okButtonBackground: "#244b81"
});




export default function Dashboard() {
  const { provider, XsynProtocol, XSynExchange, pli, account } = useContext(EthereumContext).ethereumContext;
  console.log("PLI", pli)
  //Redux 
  const dispatch = useDispatch();
  const { isAuthenticated, user, loading } = useSelector(state => state.auth)
  const { web3modal } = useSelector(state => state.web3)



  const [showStake, setshowStake] = useState(false)
  const [tillStake, settillStake] = useState(100)
  const [tokenType, settokenType] = useState("XDC")
  const [tokenCount, settokenCount] = useState(0)
  const [btndisabled, setbtndisabled] = useState(true)
  const [estimation, setestimation] = useState(0)
  const [balance, setbalance] = useState({ PLI: 0, XDC: 0 })
  const [ratio, setratio] = useState(0)
  const [currentbalance, setcurrentbalance] = useState(0)



  //Dashboard setstate

  const [data, setData] = useState({ pliDeposit: 0, xdcDeposit: 0, xdUsdminted: 0 })


  useEffect(() => {
    getBalance()
    getMyCollateralRatio();
    showMyDeposits()
    showXDUSDBalances()
  }, [])



  const getMyCollateralRatio = async (event) => {

    let addr = account;
    let response1 = await queryData(XsynProtocol, provider, 'getMyCollateralRatio', [addr]);
    console.log("response1", response1)

    var actual = await convertPricefromEth(response1["_totalActual"])
    var expected = await convertPricefromEth(response1["_totalExpected"])

    console.log("actual", actual)
    console.log("expected", expected)
    var resRatio = 0;
    if ((parseFloat(expected) > 0) && (parseFloat(actual) > 0)) {
      resRatio = ((parseFloat(expected) - parseFloat(actual)) / parseFloat(actual)) * 100;
      let userRatio = 200 + resRatio
      setratio(userRatio.toFixed(2))
    } else {
      setratio(0)
    }
    console.log("resRatio", resRatio)



  }

  const showMyDeposits = async () => {


    let addr = account;
    let response1 = await queryData(XsynProtocol, provider, 'deposits', [addr]);
    console.log("mydepositsss", response1)

    var plideposit = await convertPricefromEth(response1["pliDeposit"])
    var xdcdeposit = await convertPricefromEth(response1["xdcDeposit"])
    var xdusd = await convertPricefromEth(response1["xdUsdminted"])

    setData({ pliDeposit: plideposit, xdcDeposit: xdcdeposit, xdUsdminted: xdusd })



  }

  const getBalance = async () => {
    //XDC BALANCE 
    let accountBalance = await provider.getBalance(account);
    const xdcBalance = await convertPricefromEth(accountBalance)

    const plirate = await pli.balanceOf(account);
    const pliBalance = await convertPricefromEth(plirate)
    setbalance({ PLI: parseFloat(pliBalance), XDC: parseFloat(xdcBalance) })

  }




  useEffect(() => {
    if (parseInt(tokenCount) >= 100) {
      getEstimation()
    } else {
      setestimation(0)
    }
  }, [tokenCount, tokenType])




  const onSubmitStake = async () => {
    try {


      Loading.standard('Loading...', { messageColor: "#244b81", svgColor: "#244b81", svgSize: '50px', backgroundColor: 'rgba(255,255,255,1)', });

      if (btndisabled) {
        Notify.failure("You are Not Allowed to Stake");
        Loading.remove()
        return;
      }
      if (parseInt(tokenCount) > parseFloat(balance[tokenType])) {
        Notify.failure(`Low ${tokenType} Balance`);
        Loading.remove()
        return;
      }
      let _stakeValue = await convertPriceToEth(tokenCount, tokenType);
      var response1;
      if (tokenType === "PLI") {
        let _stakeValue = await convertPriceToEth(tokenCount, "PLI");
        let approve = await executeTransaction(
          pli,
          provider,
          "approve",
          [XsynProtocol.address, _stakeValue],
          0
        );
        response1 = await executeTransaction(XsynProtocol, provider, 'mintxdUSDForPLI', [_stakeValue, pli.address],
          0);

      } else if (tokenType === "XDC") {
        response1 = await executeTransaction(XsynProtocol, provider, 'mintxdUSDForXDC', [], _stakeValue);
      }

      console.log("response1", response1.txHash)
      var resultResponse = response1.txHash;
      if (resultResponse) {
        Loading.remove()
        Report.success(
          'Success',
          `${estimation} XDUSD successfully minted`,
          'Ok',
          () => {
            onCancel()
            getBalance()
            getMyCollateralRatio();
            showMyDeposits()
            showXDUSDBalances()
            // window.open(`${EXPLORER_URL}/${resultResponse}`)
            // window.location.reload()
          }
        );
      }
    } catch (err) {
      Loading.remove()
      Notify.failure(`Error Occured Try Again`);
    }

  }

  const onChangeCount = (e) => {

    if (parseInt(e.target.value) && parseInt(e.target.value) < 100) {
      // Notify.failure("Value Must be greater than 100");
      setbtndisabled(true)
      settokenCount(e.target.value)
      return;
    }
    setbtndisabled(false)
    settokenCount(e.target.value)
  }

  const getEstimation = async () => {

    console.log("tokenCount", tokenCount)
    console.log("tokenType", tokenType)


    let _totTokentoSend = await convertPriceToEth(tokenCount, tokenType);
    let _symbol = tokenType;
    let response1 = await queryData(XsynProtocol, provider, 'getEstimation', [_totTokentoSend, _symbol]);
    let intResult = await convertPricefromEth(response1);
    setestimation(intResult)
    console.log("response1", intResult)
  }

  const onChangeTokenType = (value) => {
    settokenType(value)
  }

  const onCancel = (e) => {
    settokenCount(0)
    settokenType("XDC")
    setbtndisabled(true)
    setestimation(0)
  }

  const onLogout = async () => {
    console.log("Logout called")
    dispatch(logoutUser())
  }

  const { isConnected } = useAccount()
  useEffect(() => {
    // This function will be called when the wallet is disconnected
    const handleDisconnect = () => {
      console.log("Wallet disconnected");
      onLogout()
      // Add your custom logic here
    };

    // Listen for account connection changes
    if (!isConnected) {
      handleDisconnect();
    }

    // Clean up event listener
    return () => {
      // If you need to clean up any listeners, do it here
    };
  }, [isConnected]);



  const showXDUSDBalances = async (symbol) => {
    let response1 = await queryData(XSynExchange, provider, 'showBalances', ["XDUSD"]);
    let bal = await convertPricefromEth(response1);
    setcurrentbalance(parseFloat(bal))
  }


  console.log("balance", balance)
  return (
    <div className="nk-app-root">
      {/* main @s */}
      <div className="nk-main ">
        {/* sidebar @s */}
        <SidebarUser />
        {/* sidebar @e */}
        {/* wrap @s */}
        <div className="nk-wrap ">

          {/* content @s */}
          <div className="nk-content nk-content-fluid">
            <div className="container-xl wide-lg">
              <div className="nk-content-body">
                <div className="nk-block-head">
                  <div className="nk-block-head-sub"><span>Welcome!!</span>
                  </div>
                  <div className="nk-block-between-md g-4">
                    <div className="nk-block-head-content">
                      <h2 className="nk-block-title fw-normal">{truncateEthAddress(user.walletAddress)}</h2>
                      <div className="nk-block-des">
                        <p>At a glance summary of your account.</p>
                      </div>
                    </div>{/* .nk-block-head-content */}
                    <div className="nk-block-head-content">
                      <ul className="nk-block-tools gx-3">
                        <li><Link to="#" onClick={(e) => {
                          e.preventDefault();
                          setshowStake(!showStake)

                        }} className="btn btn-primary"><span>Stake</span> <em className="icon ni ni-arrow-long-right" /></Link></li>
                        <li> <ConnectButton /></li>
                        {/* <li><button onClick={onLogout} className="btn btn-white btn-light"><em className="icon ni ni-signout" /> <span>Logout</span></button> </li> */}
                      </ul>
                    </div>{/* .nk-block-head-content */}
                  </div>{/* .nk-block-between */}
                </div>{/* .nk-block-head */}
                <div className="nk-block">
                  <div className="row gy-gs">
                    <div className="alert alert-pro alert-primary">
                      {" "}
                      <div className="alert-text">
                        {" "}
                        <h6>Message to community</h6>{" "}
                        <p>
                          Synthx Labs contracts are deployed in Apothem Network at the moment, so you cannot deposits real value in here.{" "}
                        </p>{" "}
                      </div>
                    </div>

                    <div className="col-lg-5 col-xl-4">
                      <div className="nk-block">
                        <div className="nk-block-head-xs">
                          <div className="nk-block-head-content">
                            <h5 className="nk-block-title title">Overview</h5>
                          </div>
                        </div>{/* .nk-block-head */}

                        <div className="nk-block">
                          <div className="card card-bordered text-light is-dark h-100">
                            <div className="card-inner">
                              <div className="nk-wg7">
                                <div className="nk-wg7-stats">
                                  <div className="nk-wg7-title">Total stakes in XDC</div>
                                  <div className="number-lg amount">{data.xdcDeposit}</div>
                                </div>



                              </div>{/* .nk-wg7 */}
                            </div>{/* .card-inner */}
                          </div>{/* .card */}
                          <div className="card card-bordered text-light is-dark h-100">
                            <div className="card-inner">
                              <div className="nk-wg7">
                                <div className="nk-wg7-stats">
                                  <div className="nk-wg7-title">Total stakes in PLI</div>
                                  <div className="number-lg amount">{data.pliDeposit}</div>
                                </div>



                              </div>{/* .nk-wg7 */}
                            </div>{/* .card-inner */}
                          </div>{/* .card */}
                          <div className="card card-bordered text-light is-dark h-100">
                            <div className="card-inner">
                              <div className="nk-wg7">
                                <div className="nk-wg7-stats">
                                  <div className="nk-wg7-title">Total XDUSD Minted</div>
                                  <div className="number-lg amount">{data.xdUsdminted} <span className="currency currency-nio">XDUSD</span></div>
                                </div>



                              </div>{/* .nk-wg7 */}
                            </div>{/* .card-inner */}
                          </div>{/* .card */}
                        </div>{/* .nk-block */}
                      </div>{/* .nk-block */}
                    </div>{/* .col */}
                    <div className="col-lg-7 col-xl-8">

                      <div className="nk-block nk-block-md">
                        <div className="nk-block-head-xs">
                          <div className="nk-block-between-md g-2">
                            <div className="nk-block-head-content">
                              <h6 className="nk-block-title title">System Overview</h6>
                            </div>
                            <div className="nk-block-head-content">
                              <Link to="/xchange" className="link link-primary">Xchange</Link>
                            </div>
                          </div>
                        </div>{/* .nk-block-head */}
                        <div className="row g-2">
                          <div className="col-sm-4">
                            <div className="card bg-light">
                              <div className="nk-wgw sm">
                                <div className="nk-wgw-inner">

                                  <div className="nk-wgw-name">
                                    <div className="nk-wgw-icon">
                                      <em className="icon ni ni-money" />
                                    </div>
                                    <h5 className="nk-wgw-title title">Your XDUSD Balance</h5>
                                  </div>
                                  <div className="nk-wgw-balance">
                                    <div className="amount">{currentbalance} <span className="currency currency-nio">XDUSD</span></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{/* .col */}
                          <div className="col-sm-4">
                            <div className="card bg-light">
                              <div className="nk-wgw sm">
                                <div className="nk-wgw-inner">

                                  <div className="nk-wgw-name">
                                    <div className="nk-wgw-icon">
                                      <em className="icon ni ni-money" />
                                    </div>
                                    <h5 className="nk-wgw-title title">System C-Ratio</h5>
                                  </div>
                                  <div className="nk-wgw-balance">
                                    <div className="amount">200%</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{/* .col */}
                          <div className="col-sm-4">
                            <div className="card bg-light">
                              <div className="nk-wgw sm">
                                <div className="nk-wgw-inner">
                                  <div className="nk-wgw-name">
                                    <div className="nk-wgw-icon">
                                      <em className="icon ni ni-money" />
                                    </div>
                                    <h5 className="nk-wgw-title title">Your  C-Ratio</h5>
                                  </div>
                                  <div className="nk-wgw-balance">
                                    <div className="amount">{ratio}% </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{/* .col */}
                          {showStake && <React.Fragment>
                            <div className='row mt-4'>
                              <div className="buysell-block">
                                <form action="#" className="buysell-form">
                                  <div className="buysell-field form-group">

                                    <div className="form-label-group">
                                      <label className="form-label">Connectd Wallet</label>
                                    </div>
                                    <div className="dropdown buysell-cc-dropdown">
                                      <div className="buysell-cc-choosen">
                                        <div className="coin-item coin-btc">
                                          <div className="coin-icon">
                                            <em className="icon ni ni-wallet" />
                                          </div>
                                          <div className="coin-info">
                                            <span className="coin-name">{user.walletAddress}</span>
                                            {/* <span className="coin-text">PLI Balance:  PLI</span>
                                            <span className="coin-text">Stake Till Now:  PLI</span> */}

                                          </div>
                                        </div>
                                      </div>
                                    </div>{/* .dropdown */}
                                  </div>{/* .buysell-field */}
                                  {/* <div class="example-alert py-2">
                                    <div class="alert alert-danger alert-icon">
                                      <em class="icon ni ni-cross-circle"></em> <strong>Low PLI Balance</strong>
                                    </div>
                                  </div> */}
                                  <div className="buysell-field form-group">
                                    <div className="form-label-group">
                                      <label className="form-label">Select Token</label>
                                    </div>
                                    <div className="form-pm-group">
                                      <ul className="buysell-pm-list">
                                        <li className="buysell-pm-item">
                                          <input onChange={() => onChangeTokenType("XDC")} checked={tokenType === "XDC"} className="buysell-pm-control" type="radio" name="bs-method" id="5pli" />
                                          <label className="buysell-pm-label" htmlFor="5pli">
                                            <span className="pm-icon">XDC</span>
                                          </label>
                                        </li>
                                        <li className="buysell-pm-item">
                                          <input onChange={() => onChangeTokenType("PLI")} checked={tokenType === "PLI"} className="buysell-pm-control" type="radio" name="bs-method" id="10pli" />
                                          <label className="buysell-pm-label" htmlFor="10pli">
                                            <span className="pm-icon">PLI</span>
                                          </label>
                                        </li>


                                      </ul>
                                    </div>
                                  </div>
                                  <div className="buysell-field form-group">
                                    <div className="form-label-group">
                                      <label className="form-label" htmlFor="buysell-amount">Number of {tokenType} Deposit</label>
                                    </div>
                                    <div className="form-control-group">
                                      <input type="text" onChange={(e) => onChangeCount(e)} value={tokenCount} className="form-control form-control-lg form-control-number" id="buysell-amount" name="bs-amount" placeholder="100" />
                                      <div className="form-dropdown">
                                        <div className="text">{tokenType}</div>

                                      </div>
                                    </div>
                                    <div className="form-note-group">
                                      <span className="buysell-min form-note-alt">Minimum: 100 {tokenType}</span>
                                      <span class="buysell-rate form-note-alt text-danger"><b>{estimation} XDUSD will be Minted</b></span>

                                    </div>
                                  </div>

                                  <div className='text-center'>
                                    <button type="button" onClick={onSubmitStake} disabled={btndisabled} className="btn btn-lg  btn-primary" >Proceed</button>
                                    <button type="button" onClick={onCancel} className="btn mx-3 btn-danger btn-lg btn-primary" >Cancel</button>
                                  </div>


                                  {/* .buysell-field */}
                                </form>{/* .buysell-form */}
                              </div>
                            </div>
                          </React.Fragment>}


                        </div>{/* .row */}
                      </div> {/* .nk-block */}
                    </div>{/* .col */}
                  </div>{/* .row */}
                </div>{/* .nk-block */}

              </div>
            </div>
          </div>
          {/* content @e */}
          <Footer />
          {/* footer @e */}
        </div>
        {/* wrap @e */}
      </div>
      {/* main @e */}
    </div>
  )
}




