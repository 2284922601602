import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { logoutUser } from '../../actions/authAction';


class Header extends Component {
  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutUser();
  }
  render() {
    const { user } = this.props.auth;
    return (
      <div className="nk-header nk-header-fluid nk-header-fixed is-light">
        <div className="container-fluid">
          <div className="nk-header-wrap">
            <div className="nk-menu-trigger d-xl-none ms-n1">
              <Link to="/admin/dashboard" className="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu"><em className="icon ni ni-menu" /></Link>
            </div>
            <div className="nk-header-brand d-xl-none">
              <Link to="/admin/dashboard" className="logo-link">
                <img className="logo-light logo-img" src="./images/logo-new-2.png" srcSet="./images/logo-new-2.png 2x" alt="logo" />
                <img className="logo-dark logo-img" src="./images/logo-new-2.png" srcSet="./images/logo-new-2.png 2x" alt="logo-dark" />
              </Link>
            </div>

            <div className="nk-header-tools">
              <ul className="nk-quick-nav">

                <li className="dropdown user-dropdown">
                  <a href="#" className="dropdown-toggle" data-bs-toggle="dropdown">
                    <div className="user-toggle">
                      <div className="user-avatar sm">
                        <em className="icon ni ni-user-alt" />
                      </div>
                      <div className="user-info d-none d-md-block">
                        <div className="user-name dropdown-indicator">{user.name}</div>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-md dropdown-menu-end dropdown-menu-s1">
                    <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                      <div className="user-card">

                        <div className="user-info">
                          <span className="lead-text">{user.name}</span>
                          <span className="sub-text">{user.email}</span>
                        </div>
                      </div>
                    </div>
                    <div className="dropdown-inner user-account-info">
                      <h6 className="overline-title-alt">Current Wallet Account</h6>
                      <div className="user-balance">12.395769 <small className="currency currency-btc">PLI</small></div>
                      <div className="user-balance-sub">Locked <span>0.344939 <span className="currency currency-btc">XDC</span></span></div>
                    </div>
                    <div className="dropdown-inner">
                      <ul className="link-list">
                        <li><Link to="/admin/sitesetting"><em className="icon ni ni-setting-alt" /><span>Account Setting</span></Link></li>
                      </ul>
                    </div>
                    <div className="dropdown-inner">
                      <ul className="link-list">
                        <li><a href="#" onClick={this.onLogoutClick.bind(this)}><em className="icon ni ni-signout" /><span>Sign out</span></a></li>
                      </ul>
                    </div>
                  </div>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Header.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
})

export default connect(mapStateToProps, { logoutUser })(Header);